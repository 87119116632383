* {
  margin: 0;
  padding: 0;
}
/*
body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 99vh; 
} */

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: black;
}

a:link {
  color:#33FF00;
}

a:visited {
  color: #33FF00;
}

a:active {
  color: #33FF00;
}

.welcome {
  margin: 0;
  padding: 0;
  color: #33FF00;
  font-size: larger;
  font-family: monospace;
  text-align: center;
  padding-bottom: 1%;
  padding-top: 1%;
}

.commands {
  color: #33FF00;
  font-size: medium;
  font-family: monospace;
  text-align: center;
}

.terminalBody {
  width: 80vw;
  height: 80vh;
  padding:1%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #272822;
  position: relative;
  overflow: hidden;
  margin:0;
}

.terminalOutput {
  width:80vw;
  height: 75vh;
  white-space: pre-wrap;
  position: relative;
  color: #33FF00;
  overflow-y: scroll;
  font-family: monospace;
  font-size: large;
}

.terminalInput {
  width:80vw;
  position: relative;
  top:0;
  color: #33FF00;
  font-family: monospace;
  font-size: large;
}

.terminalInputTag {
  background-color: #272822;
  border-color: #272822;
  border-style: solid;
  outline: none;
  color: #33FF00;
  font-size: large;
  font-family: monospace;
}

.terminalHeader {
  display: flex;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;
  background-color: #38444D;
  width: 80vw;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.circle {
  height: 25px;
  width: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
}

.circle.red {
  background-color: #DC3545;
}

.circle.yellow {
  background-color: #FFC107;
}

.circle.green {
  background-color: #04AA6D;
}

.circle.red:hover {
  background-color: red;
}

.circle.yellow:hover {
  background-color: yellow;
}

.circle.green:hover {
  background-color: lime;
}

.paddle {
  --position: 40;

  position: absolute;
  background-color: #33FF00;
  top: calc(var(--position) * 1vh);
  transform: translateY(-50%);
  width: 1vh;
  height: 10vh;
}

.paddle.left {
  left: 1vw;
}

.paddle.right {
  right: 1vw;
}

.ball {
  --x: 40;
  --y: 40;
  
  position: absolute;
  background-color: #33FF00;
  left: calc(var(--x) * 1vw);
  top: calc(var(--y) * 1vh);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 2vh;
  height: 2vh;
}

.score {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 4vh;
  color: #33FF00;
  opacity: .5;
}

.playString {
  color:#33FF00;
  display: flex;
  white-space: pre-wrap;
  justify-content: center;
  animation: blinker 1s linear infinite;
  font-family: monospace;
  font-size: medium;
  align-self: center;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.score > * {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 2vh;
  margin: 1vh 0;
}

.score > :first-child {
  text-align: right;
  border-right: .5vh solid #33FF00;
}

/* All mobile CSS below */

.nameContainer {
  padding-left: 2%;
}

.name {
  margin: 0;
  color: white;
  font-weight: bold;
}

.active {
  display: flex;
  align-items: center;
}

.message {
  display: flex;
  width: 100vw;
}

.message.to {
  margin:5px;
  justify-content: right;
}

.message.from {
  margin: 5px;
  justify-content: left;
}

.from-me {
  max-width: 60vw;
  width:fit-content;
  font-size: medium;
  padding: 2% 5%;
  margin-left: 5px;
  border-radius: 25px;
  background-color: #4C4E52;
  color: white;
  white-space: pre-line;
}

.to-me {
  max-width: 60%;
  font-size: medium;
  width:fit-content;
  padding: 2% 5%;
  border-radius: 25px;
  margin-right: 5px;  
  background-color: green;
  color: white;
}

.to-me.last {
  border-bottom-right-radius: 0px;
}

.from-me.last {
  border-bottom-left-radius: 0px;
}

.inputBox {
  margin: 3%;
  padding: 1%;
  color: white;
  font-size: medium;
  outline: none;
  background-color: #4C4E52;
  border-color: #4C4E52;
  border-style: solid;
  border-radius: 25px;
}

.activeText {
  margin: 0;
  color:lightgray;
  font-size: small;
  padding-left: 2%;
}

.activeCircle {
  background-color: lime;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.mobileContainer {
  display: flex;
  flex-flow: column;
  height: 100vh;
  background-color: black;
}

.header {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 2%;
  height: fit-content;
}

.screen {
  flex: 1;
  overflow-y: scroll;
}

.footer {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.profile-pic {
  border-radius: 50%;
  height: 20vmin;
}

.profile-pic img {
  max-width: 100%;
}

.info-pic {
  padding: 4%;
  border-radius: 50%;
  height: 6vmin;
  position:absolute;
  top:0;
  right:0;
}

.info pic img {
  max-width: 100%;
}

.infoBlock {
  visibility: hidden;
  background-color: #4C4E52;
  padding: 4%;
  margin: 7% 8%;
  width: fit-content;
  height: fit-content;
  opacity: 80%;
  position:absolute;
  top:0;
  right:0;
}

.infoText {
  color: white;
  font-weight: bold;
}

.dotBlock {
  display: flex;
  margin-left: 5%;
  visibility: hidden;
}

/* Below is for typing dots */
.dot{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 2px;
  background-color: #4C4E52;
  animation: blinker 1.5s linear infinite;
}

.dot.two {
  animation: blinker 2s linear infinite;
}

.dot.three {
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}